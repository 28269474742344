import React, { useState, useEffect } from "react";
import "./painel.css";

const Painel = () => {
    const [vagas, setVagas] = useState([]); // Armazena as vagas cadastradas
    const [novaVaga, setNovaVaga] = useState({ titulo: "", descricao: "", vinculo: "", salario: "", local: "" }); // Dados da nova vaga
    const [editando, setEditando] = useState(false); // Flag para verificar se estamos editando uma vaga
    const [vagaEditada, setVagaEditada] = useState(null); // Guarda a vaga que está sendo editada

    // Função para listar as vagas do backend
    const listarVagas = async () => {
        const response = await fetch("http://localhost:5000/api/vagas");
        const data = await response.json();
        setVagas(data); // Atualiza o estado com as vagas do backend
    };

    // Carrega as vagas ao montar o componente
    useEffect(() => {
        listarVagas(); // Quando o componente for montado, ele irá chamar o backend para listar as vagas
    }, []);

    // Função para adicionar nova vaga
    const adicionarVaga = async () => {
        const response = await fetch("http://localhost:5000/api/vagas", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(novaVaga), // Envia os dados da nova vaga
        });
        const data = await response.json();
        if (data.success) {
            setVagas([...vagas, data.vaga]); // Adiciona a nova vaga na lista de vagas
            setNovaVaga({ titulo: "", descricao: "", vinculo: "", salario: "", local: "" }); // Limpa os campos após adicionar
        }
    };

    // Função para editar uma vaga
    const editarVaga = async () => {
        const response = await fetch(`http://localhost:5000/api/vagas/${vagaEditada._id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(novaVaga), // Envia os dados da vaga editada
        });
        const data = await response.json();
        if (data.success) {
            const vagasAtualizadas = vagas.map(vaga =>
                vaga._id === vagaEditada._id ? data.vaga : vaga // Atualiza a vaga editada
            );
            setVagas(vagasAtualizadas);
            setEditando(false); // Finaliza o modo de edição
            setNovaVaga({ titulo: "", descricao: "", vinculo: "", salario: "", local: "" }); // Limpa os campos
        }
    };

    // Função para excluir uma vaga
    const excluirVaga = async (vagaParaExcluir) => {
        const response = await fetch(`http://localhost:5000/api/vagas/${vagaParaExcluir._id}`, {
            method: "DELETE",
        });
        const data = await response.json();
        if (data.success) {
            const vagasAtualizadas = vagas.filter(vaga => vaga._id !== vagaParaExcluir._id); // Remove a vaga da lista
            setVagas(vagasAtualizadas);
        }
    };

    // Função para preencher os campos do formulário de edição
    const preencherFormulario = (vaga) => {
        setNovaVaga(vaga);
        setVagaEditada(vaga);
        setEditando(true); // Ativa o modo de edição
    };

    return (
        <div id="painel">
            <h1>Painel de Gerenciamento de Vagas</h1>

            {/* Formulário para adicionar ou editar vaga */}
            <div>
                <h2>{editando ? "Editar Vaga" : "Adicionar Vaga"}</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        editando ? editarVaga() : adicionarVaga();
                    }}
                >
                    <div>
                        <label>Título</label>
                        <input type="text" value={novaVaga.titulo} onChange={(e) => setNovaVaga({ ...novaVaga, titulo: e.target.value })} required />
                    </div>
                    <div>
                        <label>Descrição</label><br />
                        <textarea cols={58} rows={10} type="text" value={novaVaga.descricao} onChange={(e) => setNovaVaga({ ...novaVaga, descricao: e.target.value })} required />
                    </div>

                    <div className="row">
                        <div className="col-md-6">

                            <label>Vínculo</label>
                            <input type="text" value={novaVaga.vinculo} onChange={(e) => setNovaVaga({ ...novaVaga, vinculo: e.target.value })} required />

                        </div>
                        <div className="col-md-6">

                            <label>Salário</label>
                            <input type="text" value={novaVaga.salario} onChange={(e) => setNovaVaga({ ...novaVaga, salario: e.target.value })} placeholder="R$" required />

                        </div>
                    </div>
                    <div>
                        <label>Localização</label>
                        <input type="text" value={novaVaga.local} onChange={(e) => setNovaVaga({ ...novaVaga, local: e.target.value })} required />
                    </div>
                   <center> <button type="submit">{editando ? "Salvar" : "Adicionar"}</button></center>
                </form>
            </div>

            {/* Lista de vagas */}
            <div>
                <h2>Vagas Cadastradas</h2>
                <div className="container">
                    <div className="row">
                        {vagas.length === 0 ? (
                            <p>Não há vagas cadastradas.</p>
                        ) : (
                            vagas.map((vaga, index) => (
                                <div key={index} className="col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{vaga.titulo}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted">{vaga.local}</h6>
                                            <p className="card-text">{vaga.descricao}</p>
                                            <p className="card-text"><strong>R${vaga.salario}</strong></p>
                                            <p className="card-text"><strong>{vaga.vinculo}</strong></p>
                                            
                                            <div className="linha">
                                                <button className="btn btn-warning" onClick={() => preencherFormulario(vaga)}>Editar</button>
                                                <button className="btn btn-danger" onClick={() => excluirVaga(vaga)}>Excluir</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Painel;
